<template>
  <v-dialog v-model="dialogShow" width="450px" content-class="company select-style-number customEditModal">
    <v-card class="pa-5">
      <h1>SELECT STYLE NUMBER</h1>

      <div class="mt-10 content">
        <div class="style-no-list">
          <div
            class="style-no d-flex"
            v-for="(style, styleIndex) in styleList.filter(s => s.isCreate === true || hasValue(s.styleNo))"
            :key="`style ${styleIndex}`">
            <!-- 생성 -->
            <template v-if="style.isCreate === true">
              <v-text-field solo flat dense outlined hide-details background-color="white" v-model="style.styleNo">
                <template v-slot:label>
                  <span class="font-error">Required</span>
                </template>
              </v-text-field>
              <v-spacer />
              <v-btn outlined color="secondary" small @click="saveStyle(style)" :loading="style.saveLoading">
                SAVE
              </v-btn>
              <v-btn outlined color="secondary" small @click="deleteStyle(style)">CANCEL</v-btn>
            </template>

            <!-- 수정 -->
            <template v-else>
              <v-checkbox v-model="style.isUse" true-value="Y" false-value="N" color="secondary" />
              {{ style.styleNo }}
              <v-spacer />
              <v-btn color="secondary" outlined fab x-small @click="deleteStyle(style)" :loading="style.deleteLoading">
                <v-icon> mdi-minus </v-icon>
              </v-btn>
            </template>
          </div>
        </div>

        <div class="register d-flex pointer" @click="addStyle">
          <v-icon color="secondary">mdi-plus-box</v-icon> ADD NEW STYLE NUMBER
        </div>

        <div class="actions d-flex justify-center">
          <v-btn :elevation="0" color="secondary" @click="save" small :disabled="styleList.every(s => s.isUse === 'N')">
            OK
          </v-btn>
          <v-btn :elevation="0" color="secondary" small @click="dialogShow = false"> CANCEL </v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { hasValue, _deepCopy } from "@/utils/util";
import codeHandler from "@/utils/codeHandler";
//#region 로직 설명
/*
  styleNo 생성, 삭제 시에는 즉시 서버에 반영되어야하고,
  체크박스는 OK를 눌렀을 경우에만 서버에 반영되어야함.

  따라서, styleNo 생성/삭제 시에는 내부 리스트에 반영 + 부모에게 이벤트 전달,
  OK 눌렀을 경우 부모 객체 수정,
  
  해당 modal이 보여질때마다 부모 객체를 깊게 복사하여 내부 객체를 덮어씀
    -> 해당 modal을 그냥 닫으면, 부모 객체에 반영안됨. 새로 열었을때는 덮어씀.
*/
//#endregion
export default {
  props: ["show", "styleNoList", "bomUUID", "cmpyUUID"],
  data() {
    return {
      styleList: [],
    };
  },
  methods: {
    initialize() {
      this.styleList = _deepCopy(this.styleNoList);
      this.styleList.forEach(s => {
        s.saveLoading = false;
        s.deleteLoading = false;
      });
    },
    addStyle() {
      this.styleList.push({ isUse: "N", styleNo: "", isCreate: true, saveLoading: false, deleteLoading: false });
    },
    saveStyle(style) {
      const styleNo = style.styleNo;

      if (!hasValue(styleNo?.trim())) return this.$toast.warning("Style Number is required");

      for (let index = 0; index < this.styleList.length; index++) {
        const s = this.styleList[index];
        if (s === style) continue;
        if (s.styleNo === styleNo) return this.$toast.warning("Style Number is cannot be duplicated");
      }

      style.saveLoading = true;
      this.$http
        .post("/bom/style-no", {
          bomUUID: this.bomUUID,
          cmpyUUID: this.cmpyUUID,
          styleNo,
        })
        .then(res => {
          if (res.data.status === 200) {
            style.isCreate = false;
            this.$emit("addStyle", style);
          } else codeHandler(res.data);
        })
        .finally(() => (style.saveLoading = false));
    },
    deleteStyle(style) {
      if (style.isCreate)
        return this.styleList.splice(
          this.styleList.findIndex(s => s === style),
          1,
        );

      style.deleteLoading = true;
      this.$http
        .patch("/bom/style-no", {
          bomUUID: this.bomUUID,
          cmpyUUID: this.cmpyUUID,
          styleNo: style.styleNo,
        })
        .then(res => {
          if (res.data.status === 200) {
            this.styleList.splice(
              this.styleList.findIndex(s => s === style),
              1,
            );
            this.$emit("deleteStyle", style);
          } else codeHandler(res.data);
        })
        .finally(() => (style.deleteLoading = false));
    },
    save() {
      this.$emit("update:styleNoList", this.styleList);
      this.dialogShow = false;
    },
    hasValue,
  },
  computed: {
    dialogShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  watch: {
    show(newVal) {
      if (newVal) {
        //보여지도록 변경되면
        this.initialize();
      } else {
        //사라지도록 변경되면
      }
    },
  },
};
</script>

<style></style>
