<template>
  <v-dialog v-model="dialogShow" min-width="800px" content-class="company set-supplier-and-item customEditModal">
    <v-card class="pa-5">
      <h1>SET SUPPLIER & ITEM</h1>

      <div class="mt-10 content">
        <v-tabs v-model="tab">
          <v-tab v-for="item in tabItem" :key="`tab ${item.text}`">{{ item.text }}</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item v-for="(item, tabIndex) in tabItem" :key="`tabItem ${item.text}`">
            <div class="results">
              <div class="head d-flex">
                <h2>RESULTS</h2>
                <v-spacer />
                <v-btn :elevation="0" color="secondary" @click="save">ASSIGN</v-btn>
              </div>

              <div class="filters d-flex justify-space-around">
                <div v-for="key in Object.keys(item.selector)" :key="`filter ${item.value} ${key}`" class="d-flex">
                  <span>{{ item.selector[key].text }}</span>

                  <v-autocomplete
                    dense
                    solo
                    hide-details
                    flat
                    outlined
                    :loading="item.selectLoading"
                    :items="item.selector[key].list"
                    item-value="item"
                    item-text="item"
                    v-model="item.selector[key].value"
                    clearable
                    :disabled="key === 'spplrName' && hasValue(spplrName)"
                    @change="getList(tabIndex, true)">
                    <template v-slot:append>
                      <v-icon> mdi-chevron-down </v-icon>
                    </template>
                  </v-autocomplete>
                </div>
              </div>

              <div class="custom-table">
                <table>
                  <thead>
                    <tr>
                      <th :rowspan="item.value !== 'Accessory' ? 2 : 1"></th>
                      <th
                        v-for="column in item.columns"
                        :key="`thead ${item.text} ${column.text}`"
                        :rowspan="column.rowspan">
                        {{ column.text }}
                      </th>
                      <template v-if="item.value !== 'Accessory'">
                        <th colspan="2">YARN SIZE</th>
                        <th colspan="2">CONSTRUCTION</th>
                        <th colspan="2">SHRINKAGE</th>
                      </template>
                    </tr>
                    <tr v-if="item.value !== 'Accessory'">
                      <th class="right">WRAP</th>
                      <th class="right">WEFT</th>
                      <th class="right">EPI</th>
                      <th class="right">PPI</th>
                      <th class="right">+</th>
                      <th class="right">-</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(material, materialIndex) in item.items" :key="`tbody ${item.text} ${materialIndex}`">
                      <td class="check">
                        <v-checkbox
                          :input-value="selectedItem.findIndex(i => i.mtrlUUID === material.mtrlUUID) !== -1"
                          :disabled="
                            mtrlCostList.findIndex(i => i.mtrlUUID === material.mtrlUUID) !== -1 ||
                            (isCreateMode && hasValue(tempSpplrName) && material.spplrName !== tempSpplrName) ||
                            (createMode && hasValue(spplrName) && material.spplrName !== spplrName)
                          "
                          color="secondary"
                          @click="checkboxClicked(material)"></v-checkbox>
                      </td>
                      <td v-for="(column, cIndex) in item.columns" :key="`td ${item.text} ${column.text} ${cIndex}`">
                        <template v-if="!['postProcessing', 'dyeing', 'printing'].includes(column.value)">
                          <span v-html="material[column.value]"></span>
                        </template>
                        <template v-else-if="hasValue(material[column.value])">
                          <span
                            v-for="val in material[column.value].split('|$|')"
                            :key="`${column.value} ${materialIndex} ${val}`">
                            {{ val }}
                            <br />
                          </span>
                        </template>
                      </td>
                      <template v-if="item.value !== 'Accessory'">
                        <td>{{ material.yarnSizeWrap }}</td>
                        <td>{{ material.yarnSizeWeft }}</td>
                        <td>{{ material.constructionEpi }}</td>
                        <td>{{ material.constructionPpi }}</td>
                        <td>
                          {{
                            hasValue(material.shrinkagePlus) ? fixedNumber(material.shrinkagePlus, $mathFix, true) : ""
                          }}
                        </td>
                        <td>
                          {{
                            hasValue(material.shrinkageMinus)
                              ? fixedNumber(material.shrinkageMinus, $mathFix, true)
                              : ""
                          }}
                        </td>
                      </template>
                    </tr>
                    <tr v-if="item.items.length < 1">
                      <td colspan="100">{{ $t("common.text.noData") }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="text-center pt-2" v-if="item.pageCount > 0">
                <v-pagination
                  v-model="item.page"
                  :length="item.pageCount"
                  color="secondary"
                  @input="() => getList(tabIndex)"
                  :total-visible="$pagePV"></v-pagination>
              </div>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </v-card>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
import codeHandler from "@/utils/codeHandler";
import { fixedNumber, hasValue } from "@/utils/util";

export default {
  props: ["show", "mtrlCostList", "spplrName", "createMode", "parentIsPO"],
  data() {
    return {
      tab: 0,
      tabItem: [
        {
          text: "FABRIC",
          value: "Fabric",
          selectLoading: false,
          selector: {
            spplrName: { text: "SUPPLIER", value: "", list: [] },
            itemName: { text: "MILL NAME", value: "", list: [] },
            mtrlType: { text: "FABRIC TYPE", value: "", list: [] },
            mtrlName: { text: "COMPOSITION", value: "", list: [] },
          },
          columns: [
            { text: "SUPPLIER", value: "spplrName", rowspan: 2 },
            { text: "MILL #", value: "itemNo", rowspan: 2 },
            { text: "RD #", value: "rdNo", rowspan: 2 },
            { text: "FABRIC TYPE ", value: "mtrlType", rowspan: 2 },
            { text: "FABRIC NAME ", value: "itemName", rowspan: 2 },
            { text: "COMPOSITION ", value: "itemDetail", rowspan: 2 },
            { text: "POST PROCESSING ", value: "postProcessing", rowspan: 2 },
            { text: "DYEING ", value: "dyeing", rowspan: 2 },
            { text: "PRINTING ", value: "printing", rowspan: 2 },
            { text: "WEIGHT (UOM) ", value: "weight", rowspan: 2 },
            { text: "STRUCTURE", value: "structure", rowspan: 2 },
          ],
          page: 1,
          pageCount: 0,
          items: [],
        },
        {
          text: "TRIM",
          value: "Trim",
          selectLoading: false,
          selector: {
            spplrName: { text: "SUPPLIER", value: "", list: [] },
            itemName: { text: "ITEM NAME", value: "", list: [] },
            mtrlType: { text: "TRIM TYPE", value: "", list: [] },
            mtrlName: { text: "ITEM DETAIL", value: "", list: [] },
          },
          columns: [
            { text: "SUPPLIER", value: "spplrName", rowspan: 2 },
            { text: "MILL #", value: "itemNo", rowspan: 2 },
            { text: "RD #", value: "rdNo", rowspan: 2 },
            { text: "TRIM TYPE ", value: "mtrlType", rowspan: 2 },
            { text: "ITEM NAME ", value: "itemName", rowspan: 2 },
            { text: "ITEM DETAIL ", value: "itemDetail", rowspan: 2 },
            { text: "WEIGHT (UOM) ", value: "weight", rowspan: 2 },
            { text: "STRUCTURE", value: "structure", rowspan: 2 },
          ],
          page: 1,
          pageCount: 0,
          items: [],
        },
        {
          text: "ACCESSORY",
          value: "Accessory",
          selectLoading: false,
          selector: {
            spplrName: { text: "SUPPLIER", value: "", list: [] },
            itemName: { text: "ITEM NAME", value: "", list: [] },
            mtrlType: { text: "ACCESSORY TYPE", value: "", list: [] },
            mtrlName: { text: "ITEM DETAIL", value: "", list: [] },
          },
          columns: [
            { text: "SUPPLIER", value: "spplrName", rowspan: 1 },
            { text: "ITEM #", value: "itemNo", rowspan: 1 },
            { text: "RD #", value: "rdNo", rowspan: 1 },
            { text: "ACCESSORY TYPE ", value: "mtrlType", rowspan: 1 },
            { text: "ITEM NAME ", value: "itemName", rowspan: 1 },
            { text: "ITEM DETAIL ", value: "itemDetail", rowspan: 1 },
          ],
          page: 1,
          pageCount: 0,
          items: [],
        },
      ],
      selectedItem: [],
      itemsPerPage: 10,
      loading: false,
      tempSpplrName: "",
    };
  },
  methods: {
    getSelectList(index) {
      index = parseInt(index);
      this.tabItem[index].selectLoading = true;
      this.$http
        .get("/cbd/mtrl-tab-menu", {
          data: {
            mtrlCate: this.tabItem[index].value,
            cmpyUUID: this.$store.getters.getCmpyUUID,
            isPO: hasValue(this.parentIsPO),
          },
        })
        .then(res => {
          if (res.data.status === 200) {
            if (!Array.isArray(res.data.data)) return;

            const selector = this.tabItem[index].selector;
            selector.spplrName.list = res.data.data.filter(d => d.menu === "spplrName");
            selector.itemName.list = res.data.data.filter(d => d.menu === "itemName");
            selector.mtrlType.list = res.data.data.filter(d => d.menu === "mtrlType");
            selector.mtrlName.list = res.data.data.filter(d => d.menu === "mtrlName");
          } else codeHandler(res.data);
        })
        .finally(() => (this.tabItem[index].selectLoading = false));
    },
    getList(index, changeFilters) {
      if (changeFilters) this.tabItem[index].page = 1;

      const mtrlCate = this.tabItem[index].value;
      const spplrName = hasValue(this.spplrName) ? this.spplrName : this.tabItem[index].selector.spplrName.value;
      const itemName = this.tabItem[index].selector.itemName.value;
      const mtrlType = this.tabItem[index].selector.mtrlType.value;
      const mtrlName = this.tabItem[index].selector.mtrlName.value;
      const currentPageNo = this.tabItem[index].page;

      this.loading = true;
      this.$http
        .get("/cbd/material", {
          data: {
            cmpyUUID: this.$store.getters.getCmpyUUID,
            mtrlCate,
            spplrName,
            itemName,
            mtrlType,
            mtrlName,
            currentPageNo,
            isPO: hasValue(this.parentIsPO),
          },
        })
        .then(res => {
          if (res.data.status === 200) {
            this.tabItem[index].items = res.data.data;
            this.tabItem[index].pageCount = Math.ceil((res.data.data[0]?.totalCnt || 0) / this.itemsPerPage);
          } else codeHandler(res.data);
        })
        .finally(() => (this.loading = false));
    },
    checkboxClicked(mtrl) {
      const index = this.selectedItem.findIndex(i => i.mtrlUUID === mtrl.mtrlUUID);

      if (index === -1) {
        if (this.isCreateMode && this.selectedItem.length === 0) this.tempSpplrName = mtrl.spplrName;
        this.selectedItem.push(mtrl);
      } else {
        if (this.isCreateMode && this.selectedItem.length === 1) this.tempSpplrName = "";
        this.selectedItem.splice(index, 1);
      }
    },
    save() {
      this.$emit("save", this.selectedItem);
      this.dialogShow = false;
    },
    hasValue,
    fixedNumber,
  },
  watch: {
    tab(newVal) {
      if (this.tabItem[parseInt(newVal)].selector.spplrName.list.length < 1) this.getSelectList(newVal);
    },
    spplrName(newVal) {
      if (hasValue(newVal)) {
        this.tabItem.forEach((item, index) => {
          item.selector.spplrName.value = newVal;
          this.getSelectList(index);
          this.getList(index);
        });
      }
    },
  },
  computed: {
    dialogShow: {
      get() {
        return this.show;
      },
      set(value) {
        if (this.isCreateMode && this.selectedItem.length > 0) this.tempSpplrName = "";
        this.selectedItem = [];
        this.$emit("update:show", value);
      },
    },
    isCreateMode() {
      return this.createMode && !hasValue(this.spplrName);
    },
  },
  created() {
    this.tabItem.forEach((_, index) => {
      this.getSelectList(index);
      this.getList(index);
    });
  },
};
</script>

<style></style>
