<template>
  <div class="d-flex pointer custom-input-switch" @click="callback($event)">
    <span :style="`color: ${isActive ? activeColor : inactiveColor}`">
      {{ label }}
    </span>
    <div :class="[{ active: isActive }, 'switch']" :style="`background: ${isActive ? activeColor : inactiveColor}`">
      <div class="switch-btn"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    inactiveColor: {
      type: String,
      default: "#eeeeee",
    },
    activeColor: {
      type: String,
      default: "#3a989e",
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
  },
  methods: {
    callback(e) {
      this.$emit("click", e);
    },
  },
  computed: {
    cssProps() {
      return {
        "--bg-active-color": this.activeColor,
        "--bg-inactive-color": this.inactiveColor,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-input-switch {
  .switch {
    // background: #eee;
    display: block;
    font-size: 14px;
    padding: 5px 15px;
    position: relative;
    width: 30px;
    height: 20px;
    border-radius: 30px;
    transition: 0.3s;

    &::before {
      content: "";
      display: block;
    }

    // &.active {
    //   background: #eb5757;
    // }

    .switch-btn {
      background: #fff;
      border-radius: 50%;
      display: block;
      height: 30px;
      left: 3px;
      position: absolute;
      top: 50%;
      width: 20px;
      height: 20px;
      transform: translate(0, -50%);
      transition: 0.3s;
    }

    &.active .switch-btn {
      left: 60%;
      transition: 0.3s;
    }
  }
}
</style>
