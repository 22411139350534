import { render, staticRenderFns } from "./SetSupplierItem.vue?vue&type=template&id=68ce2a11&"
import script from "./SetSupplierItem.vue?vue&type=script&lang=js&"
export * from "./SetSupplierItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
installComponents(component, {VAutocomplete,VBtn,VCard,VCheckbox,VDialog,VIcon,VOverlay,VPagination,VProgressCircular,VSpacer,VTab,VTabItem,VTabs,VTabsItems})
